export default {
    name: "test_origami_1",
    paper:
        { height: 3, width: 4 },
    zones:
        [
            {
                name: 'recto',
                points: [
                    { id: 0, x: 0, y: 0 },
                    { id: 1, x: 2, y: 0 },
                    { id: 2, x: 0, y: 1 },
                ],
                plot_segs: [
                    { p0: 0, p1: 1 },
                    { p0: 1, p1: 2 },
                    { p0: 2, p1: 0 },
                ],
                faces: [
                    { id: 'A', pts: [0, 1, 2], target_side: 'recto', Tside_fold: { a: 1, r: 0, t: { x: 0, y: 0 } } },
                ],
            }
        ]

}